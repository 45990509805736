<template>
  <div>
    <div class="grid grid-cols-2">
      <div class="justify-self-start">
        <h1>Projects</h1>
      </div>
      <div class="justify-self-end">
        <router-link :to="`/projects/create`" class="p-2 pr-3 pl-3 text-white font-light bg-gray-700 rounded inline-block">
          New project
        </router-link>
      </div>
    </div>
    
    <vue-good-table
      :columns="columns"
      :rows="projects"
      :search-options="{
        enabled: true,
        placeholder: 'Search for project...'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 50,
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <router-link :to="`/projects/${props.row.id}/recipients`" v-if="props.column.field == 'name'">
          {{ props.row.name }}
        </router-link>
        <span v-else-if="props.column.field == 'scheduler.is_enabled'">
          <span v-if="props.row.scheduler.is_enabled" class="w-full block bg-green-100 text-green-700 text-base text-center	font-semibold rounded-lg">
            Active
          </span>
          <span v-if="!props.row.scheduler.is_enabled" class="w-full block bg-red-100 text-red-700 text-base text-center	font-semibold rounded-lg">
            Inactive
          </span>
        </span>
        <span class="w-full block bg-green-100 text-green-700 text-base text-center font-semibold rounded-lg" v-else-if="props.column.field == 'receivers.sent'">
          {{ props.row.receivers.sent }} / {{ props.row.receivers.ready }}
        </span>
        <span class="w-full block bg-blue-100 text-blue-700 text-base text-center	font-semibold rounded-lg" v-else-if="props.column.field == 'receivers.scraped'">
          {{ props.row.receivers.scraped }} / {{ props.row.receivers.total_count }}
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          tdClass: 'vgt-left-align'
        },
        {
          label: 'Account',
          field: 'account.name',
          tdClass: 'vgt-left-align'
        },
        {
          label: 'Created at',
          field: 'created_at',
          width: '160px',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM/yyyy HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
        {
          label: 'Scheduler',
          field: 'scheduler.is_enabled',
          tdClass: 'vgt-left-align',
          width: '150px',
          globalSearchDisabled: true,
        },
        {
          label: 'Next execution',
          field: 'scheduler.next_cron_run',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM/yyyy HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          width: '160px',
        },
        {
          label: '# Sent',
          field: 'receivers.sent',
          tdClass: 'vgt-left-align',
          thClass: 'vgt-left-align',
          width: '150px',
          type: 'number',
          globalSearchDisabled: true,
        },
        {
          label: '# Scraped',
          field: 'receivers.scraped',
          tdClass: 'vgt-left-align',
          thClass: 'vgt-left-align',
          width: '150px',
          type: 'number',
          globalSearchDisabled: true,
        },
      ],
      projects: []
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_API_URL}/projects`)
      .then(response => {
        this.projects = response.data;
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>