var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-2"},[_vm._m(0),_c('div',{staticClass:"justify-self-end"},[_c('router-link',{staticClass:"p-2 pr-3 pl-3 text-white font-light bg-gray-700 rounded inline-block",attrs:{"to":"/projects/create"}},[_vm._v(" New project ")])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.projects,"search-options":{
      enabled: true,
      placeholder: 'Search for project...'
    },"pagination-options":{
      enabled: true,
      perPage: 50,
      dropdownAllowAll: false,
      perPageDropdownEnabled: false,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('router-link',{attrs:{"to":("/projects/" + (props.row.id) + "/recipients")}},[_vm._v(" "+_vm._s(props.row.name)+" ")]):(props.column.field == 'scheduler.is_enabled')?_c('span',[(props.row.scheduler.is_enabled)?_c('span',{staticClass:"w-full block bg-green-100 text-green-700 text-base text-center\tfont-semibold rounded-lg"},[_vm._v(" Active ")]):_vm._e(),(!props.row.scheduler.is_enabled)?_c('span',{staticClass:"w-full block bg-red-100 text-red-700 text-base text-center\tfont-semibold rounded-lg"},[_vm._v(" Inactive ")]):_vm._e()]):(props.column.field == 'receivers.sent')?_c('span',{staticClass:"w-full block bg-green-100 text-green-700 text-base text-center font-semibold rounded-lg"},[_vm._v(" "+_vm._s(props.row.receivers.sent)+" / "+_vm._s(props.row.receivers.ready)+" ")]):(props.column.field == 'receivers.scraped')?_c('span',{staticClass:"w-full block bg-blue-100 text-blue-700 text-base text-center\tfont-semibold rounded-lg"},[_vm._v(" "+_vm._s(props.row.receivers.scraped)+" / "+_vm._s(props.row.receivers.total_count)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"justify-self-start"},[_c('h1',[_vm._v("Projects")])])}]

export { render, staticRenderFns }